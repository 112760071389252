.custom-slider-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .custom-slider {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .custom-slide {
    width: 100%;
    max-width: 33.3%;
    height: 320px;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    .custom-slide {
      width: 100%;
      max-width: 100%;
      height: "100%";
      object-fit: cover;
    }
  }

  @media (min-width: 1200px) {
    .custom-slide {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 440px;
      object-position: center;
      object-fit: cover;
    }
  }
  
  .custom-slider-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .custom-slider-control-left {
    left: 0;
  }
  
  .custom-slider-control-right {
    right: 0;
  }
  
  .custom-slider-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .custom-slider-indicator {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .custom-slider-indicator.active {
    background-color: #D0963E;
  }
  